import React from 'react'
import styles from './welcome.module.css'
import { Button } from '../../components'

import logo from '../../assets/images/logo.svg'
import coin1 from '../../assets/images/coin-1.png'
import coin2 from '../../assets/images/coin-2.png'
import coin3 from '../../assets/images/coin-3.png'
import coin4 from '../../assets/images/coin-4.png'
import coin5 from '../../assets/images/coin-5.png'

export const Step2 = () => {
	return (
		<div className='page'>
			<div className='page-inner page-align-bottom'>
				<div className={styles.logo}>
					<img src={logo} alt='StSwap Wallet' />
					<div className={styles.title}>
						A non-custodial <br /> & secure wallet for
					</div>
					<div className={styles.coins}>
						<img src={coin1} />
						<img src={coin2} />
						<img src={coin3} />
						<img src={coin4} />
						<img src={coin5} />
						<span className={styles.coinsMore}>
							+ <br /> more
						</span>
					</div>
				</div>
				<Button variant='default' to='/step3'>
					Continue
				</Button>
				<Button variant='inline' to='/start'>
					Skip
				</Button>
			</div>
		</div>
	)
}
