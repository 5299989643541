import React from 'react'
import ContentLoader from 'react-content-loader'

export const PriceLoader = () => {
	return (
		<ContentLoader
			speed={2}
			width={130}
			height={70}
			viewBox='0 0 130 70'
			backgroundColor='#223844'
			foregroundColor='#0b212c'>
			<rect x='0' y='0' rx='6' ry='6' width='130' height='30' />
			<rect x='0' y='43' rx='6' ry='6' width='100' height='25' />
		</ContentLoader>
	)
}
