import React from 'react'
import Svg from '../../../svgs/Svg'
import classNames from 'classnames/bind'
import styles from './Button.module.css'
import { Link } from 'react-router-dom'

export const Button = ({
	to = '',
	onClick,
	className,
	children,
	variant,
	icon,
	color,
	colorText,
}) => {
	const variants = {
		outlined: styles.outlined,
		actions: styles.actions,
		disabled: styles.disabled,
		inline: styles.inline,
		bar: styles.bar,
	}

	const buttonClasses = classNames(styles.btn, variants[variant], className)
	if (to !== '') {
		return (
			<Link
				to={to}
				className={buttonClasses}
				style={{
					backgroundColor: color,
					color: colorText,
					textDecoration: 'none',
				}}>
					{icon && (
						<div className={styles.icon}>
							<Svg type={icon} className='icon' />
						</div>
					)}
					{children}
			</Link>
		)
	} else {
		return (
			<button
				onClick={onClick}
				className={buttonClasses}
				style={{ backgroundColor: color, color: colorText }}>
					{icon && (
						<div className={styles.icon}>
							<Svg type={icon} className='icon' />
						</div>
					)}
					{children}
			</button>
		)
	}
}
