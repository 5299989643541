import React from 'react'
import styles from './import.module.css'
import { ButtonLink, Header } from '../../components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const ImportStart = () => {
	const navigate = useNavigate()
	const { backup } = useSelector((state) => state.storage)

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header />
				<div className='title'>Follow these steps to import your wallet</div>

				<div className={styles.list}>
					<ButtonLink
						onClick={() => navigate('/backup')}
						title='Create a backup for you current StSwap Wallet'
						subtitle='This step is required, even if your current Name wallet is empty, for security.'
					/>
					<ButtonLink
						disabled={!backup}
						onClick={() => navigate('/import')}
						title='Import your other wallet using a recovery phrase'
					/>
				</div>
			</div>
		</div>
	)
}
