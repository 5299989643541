import React from 'react';
import styles from './backup.module.css';
import { Button, Menu } from '../../components';

import welcome from '../../assets/images/welcome.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Backup = () => {
	const { usePin } = useSelector((state) => state.storage);
	const navigate = useNavigate();

	const goBackUp = () => {
		if (usePin) {
			navigate('/login-pin', { state: { to: '/backup-start' } });
		} else {
			navigate('/backup-start');
		}
	};

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className={styles.logo}>
					<div className={styles.title}>Manual backup</div>
					<img width={200} src={welcome} alt='StSwap Wallet' />
				</div>
				<Button variant='default' onClick={goBackUp}>
					Perform manual backup
				</Button>
			</div>
		</div>
	);
};
