import React from 'react';
import styles from './ImageBlock.module.css';

export const ImageBlock = ({ widthImg, title, subtitle, image }) => {
	return (
		<div className={styles.wrapper}>
			<img width={widthImg} src={image} style={{ maxWidth: 300 }} alt={title} />
			<div className={styles.title}>{title}</div>
			<div className={styles.subtitle}>{subtitle}</div>
		</div>
	);
};
