import React from 'react'
import styles from './welcome.module.css'
import { Button } from '../../components'

import logo from '../../assets/images/logo.svg'

export const Step1 = () => {
	return (
		<div className='page'>
			<div className='page-inner page-align-bottom'>
				<div className={styles.logo}>
					<img src={logo} alt='StSwap Wallet' />
					<div className={styles.title}>
						The crypto wallet <br /> for everyone
					</div>
				</div>
				<Button to='/step2' variant='default'>
					Continue
				</Button>
				<Button variant='inline' to='/start'>
					Skip
				</Button>
			</div>
		</div>
	)
}
