import React from 'react'
import ContentLoader from 'react-content-loader'

export const ChartLoader = ({ style }) => {
	return (
		<ContentLoader
			style={style}
			speed={2}
			width={350}
			height={80}
			viewBox='0 0 350 80'
			backgroundColor='#223844'
			foregroundColor='#0b212c'>
			<rect x='0' y='0' rx='6' ry='6' width='350' height='80' />
		</ContentLoader>
	)
}
